import React, { useState } from 'react'
import { IWarning } from '../../../lib/interfaces'
import { Window } from '../templates'
import { BsExclamationLg } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { actionCreators } from '../../../lib/state'
import { NoticeAcknowledged, NoticeStatus } from '../../../lib/localstorageManager'

const Notice: React.FC<IWarning> = (props) => {

  let key = "notice-widget"

  const [width] = useState(350)
  const [isFirstRun] = useState(NoticeStatus())
  const dispatch = useDispatch()
  const {
    toggleWidget,
    unsubscribeWidget
  } = bindActionCreators(actionCreators, dispatch)

  const handleClose = () => {
    toggleWidget(key)
    unsubscribeWidget(key)
    NoticeAcknowledged();
  }

  if(!isFirstRun) return null

  return (
    <Window width={width} title="Notice" icon={BsExclamationLg} defaultActive defaultPosition={{x: 50, y: 20}}>
      <div className='h-40 px-8 py-6 flex flex-col justify-between items-center'>
        <span className='text-light-bg-100 dark:text-dark-bg-900 text-center'>
          Just taking a <span className='text-danger-500'>PAUSE</span> here while I sort out the back-end of this project.
        </span>

        <button
          onClick={handleClose}
          className={`ring-1 p-2 px-8 hover:bg-light-bg-900 hover:dark:bg-dark-bg-100 corners flex justify-center items-center`}>
          <span className='text-xs text-light-bg-100 dark:text-dark-bg-900'>OK</span>
        </button>
      </div>
    </Window>
  )
}

export default Notice