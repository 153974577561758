import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import App from './App';
import { ThemeProvider } from './lib/themecontext';
import { Provider } from 'react-redux';
import { store } from './lib/state';
import PreLoad from './preload';
import { isMobile } from 'react-device-detect';

import { 
  InitialAcknowledgeNotice, 
  InitialSavedWidget 
} from './lib/localstorageManager';
import PhoneApp from './Phone';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

InitialAcknowledgeNotice();
InitialSavedWidget();

root.render(
  <ThemeProvider initialTheme>
    <Provider store={store}>
      {
        isMobile ? <PhoneApp /> : <PreLoad><App /></PreLoad>
      }
    </Provider>
  </ThemeProvider>
);
