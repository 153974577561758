import { IAccountState } from "../../interfaces";
import { AccountAction } from "../actions";
import { AccountActionTypes } from "../actionTypes";



const initialState : IAccountState = {
    account: null
}

const AccountReducer = (state = initialState, action: AccountAction) => {
    switch(action.type) {
        case AccountActionTypes.SET_ACCOUNT:
            return {
                ...state,
                account: action.account
            }
        case AccountActionTypes.REMOVE_ACCOUNT:
            return {
                ...state,
                account: null
            }
        case AccountActionTypes.CHANGE_ACCOUNT_NAME:
            return {
                ...state,
                account: {
                    ...state.account,
                    name: action.value
                }
            }
        case AccountActionTypes.CHANGE_ACCOUNT_SHORTNAME:
            return {
                ...state,
                account: {
                    ...state.account,
                    shortName: action.value
                }
            }
        case AccountActionTypes.CHANGE_ACCOUNT_EMAIL:
            return {
                ...state,
                account: {
                    ...state.account,
                    email: action.value
                }
            }
        case AccountActionTypes.CHANGE_ACCOUNT_BIO:
            return {
                ...state,
                account: {
                    ...state.account,
                    bio: action.value
                }
            }
        default:
            return state;
    }
}

export default AccountReducer;

export type AccountReducerState = ReturnType<typeof AccountReducer>;