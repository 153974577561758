import React, { useState } from 'react'
import { useDispatch, connect, Provider } from 'react-redux'
import { actionCreators } from '../../lib/state'
import { bindActionCreators } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { TWidgetState } from '../../lib/types'
import { IWidgetState } from '../../lib/interfaces'
import { Task } from './templates'
import { motion } from 'framer-motion'
import { SiIfixit } from 'react-icons/si'
import { EToolTipDirection, EToolTipType } from '../../lib/enums'

const MenuContext = React.createContext<{
  setName: (name: string) => void
} | null>(null)

const Menu: React.FC = () => {

  const dispatch = useDispatch()
  const {
    toggleWidget,
  } = bindActionCreators(actionCreators, dispatch)
  const state = useSelector((state: IWidgetState) => state.widgets.widget)

  const MenuItem = (widget: TWidgetState, menustate: number) => {

    const menuContext = React.useContext(MenuContext)

    const handleClick = (id: string) => {
      toggleWidget(id)
    }

    return (
      <>
        <motion.button
          onMouseEnter={() => menuContext?.setName(widget.name)}
          onMouseLeave={() => menuContext?.setName('')}
          key={widget.id}
          whileTap={{ scale: 1 }}
          whileHover={{ scale: 1.2 }}
          className={`${!widget.active ? 'bg-dark-bg-800 dark:bg-light-bg-100 text-light-bg-100 dark:text-dark-bg-800' : 'bg-light-bg-100 dark:bg-dark-bg-800 text-dark-bg-800 dark:text-light-bg-100 '} relative corners cursor-pointer p-1`}
          onClick={() => handleClick(widget.id)}>
          {widget.icon}
        </motion.button>
      </>
    )
  }

  const MenuList = (subscribedwidgets: IWidgetState) => {

    const reversed = subscribedwidgets.widget.slice().reverse()

    return (
      <>
        {reversed.map((widget: TWidgetState, i: number) => <MenuItem key={widget.name} {...widget} />)}
      </>
    )
  }

  const mapStatetoProps = ({ widgets }: any) => ({ subscribedwidgets: widgets }),
    MenuListComponent = connect(mapStatetoProps)(MenuList)

  const [hoveredName, setHoveredName] = React.useState('')

  const tooltip = {
    width: 11.1 * hoveredName.length,
    type: EToolTipType.TEXT,
    direction: EToolTipDirection.TOP,
    text: hoveredName,
  }

  const handleMenuItemMouseEnter = (name: string) => {
    setHoveredName(name)
  }

  return (
    <>
      <MenuContext.Provider value={{
        setName: handleMenuItemMouseEnter,
      }}>
        {
          state.length > 0 && <Task
            ToolTip={tooltip}
            disableAnimation
            className={`flex gap-2 px-2 p-1 `
            } >
            <MenuListComponent widget={state} />
          </Task>
        }
      </MenuContext.Provider>
    </>
  )
}

// const MenuItem = () => {

//   const handleClick = (id: string) => {
//     toggleWidget(id)
//   }

//   return (
//     <>
//       <motion.button
//         onMouseEnter={() => menuContext?.setName(widget.name)}
//         onMouseLeave={() => menuContext?.setName('')}
//         key={widget.id}
//         whileTap={{ scale: 1 }}
//         whileHover={{ scale: 1.2 }}
//         className={`${!widget.active ? 'bg-dark-bg-800 dark:bg-light-bg-100 text-light-bg-100 dark:text-dark-bg-800' : 'bg-light-bg-100 dark:bg-dark-bg-800 text-dark-bg-800 dark:text-light-bg-100 '} relative corners cursor-pointer p-1`}
//         onClick={() => handleClick(widget.id)}>
//         {widget.icon}
//       </motion.button>
//     </>
//   )
// }

export default Menu