import React, { RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { WindowBar } from '../components'
import { IWidgetState, IWindow } from '../../../lib/interfaces'
import { AnimatePresence, motion } from 'framer-motion'
import Draggable from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { actionCreators } from '../../../lib/state'
import { convertPercentageToPixels, convertPixelToPercentage } from '../../../lib/helpers'
import { GetSavedWidget } from '../../../lib/localstorageManager'

const WindowUnclose: React.FC<IWindow> = (props) => {

  const key = `${props.title.toLocaleLowerCase()}-widget`

  const savedState = GetSavedWidget(key)

  const self = useRef<HTMLDivElement>(null)
  const [isActive, setIsActive] = useState(savedState?.active || props.defaultActive)
  

  // center the window on the screen
  const [width, height] = useWindowSize()
  const [selfwindow, setWindow] = useState({ width: props.width, height: 0 })
  const [position, setPosition] = useState(
    {
      x: savedState?.position.x ? convertPercentageToPixels(savedState.position.x, window.innerWidth) : props.defaultPosition ?
        convertPercentageToPixels(props.defaultPosition.x, window.innerWidth - props.width)
        : 0,
      y: savedState?.position.y ? convertPercentageToPixels(savedState.position.y, window.innerHeight - (self.current ? self.current.getBoundingClientRect().height : 0)) : props.defaultPosition ?
        convertPercentageToPixels(props.defaultPosition.y, window.innerHeight - (self.current ? self.current.getBoundingClientRect().height : 0))
        : 0
    })
  const dispatch = useDispatch()

  const {
    subscribeWidget,
    unsubscribeWidget,
    setWidgetPosition,
    toggleWidgetMinimized
  } = bindActionCreators(actionCreators, dispatch)
  const state = useSelector((state: IWidgetState) => state.widgets.widget.find((widget: IWidgetState) => widget.id === key) || { name: key, active: false })

  const [isMinimized, setIsMinimized] = useState(savedState?.isMinimized || state.isMinimized)

  useEffect(() => {
    subscribeWidget(
      key, 
      <props.icon size={23} />, 
      props.title, 
      {
        x: convertPixelToPercentage(position.x, window.innerWidth),
        y: convertPixelToPercentage(position.y, window.innerHeight),
      },
      savedState?.active || props.defaultActive,
      savedState?.isMinimized || state.isMinimized)
    return () => {
      unsubscribeWidget(key)
      setWidgetPosition(key, {
        x: convertPixelToPercentage(position.x, window.innerWidth),
        y: convertPixelToPercentage(position.y, window.innerHeight),
      })
    }
  }, [])

  const variants = {
    ondrag: {
      scale: 1.02,
    },
    offdrag: {
      scale: 1,
    },
    exit: {
      opacity: 0,
    },
    enter: {
      opacity: 1
    }
  }

  const updateWindow = () => {
    if (!self.current) return

    setWindow({
      width: self.current.getBoundingClientRect().width,
      height: self.current.getBoundingClientRect().height,
    })
  }

  const updatePosition = (e: any, data: { x: number; y: number }) => {
    if (!self.current) return

    let { x, y } = data

    const {
      height,
      width,
    } = self.current.getBoundingClientRect()

    x = x + width > window.innerWidth ? window.innerWidth - width : x
    y = y + height > window.innerHeight ? window.innerHeight - height : y

    setPosition({ x, y })
  }

  useEffect(() => {
    updateWindow()
    updatePosition(null, position)
  }, [, isMinimized, isActive, state, self.current, width, height, props.otherProps])

  useEffect(() => {
    setWidgetPosition(key, {
      x: convertPixelToPercentage(position.x, window.innerWidth),
      y: convertPixelToPercentage(position.y, window.innerHeight),
    })
  }, [self.current, width, height, props.otherProps])

  useEffect(() => {
    setIsActive(state.active)
  }, [state.active])

  const handleDrag = useCallback((e: any, data: { x: number; y: number }) => {
    updatePosition(e, data)
  }, [])

  const handleStop = useCallback((e: any, data: { x: number; y: number }) => {
    setWidgetPosition(key, {
      x: convertPixelToPercentage(data.x, window.innerWidth),
      y: convertPixelToPercentage(data.y, window.innerHeight),
    })
  }, [])

  const handleMinimize = () => {
    toggleWidgetMinimized(key)
    setIsMinimized(!isMinimized)
  }

  return (

    <Draggable
      position={position}
      handle={'.drag-area'}
      bounds={{
        left: 0,
        top: 0,
        right: window.innerWidth - selfwindow.width,
        bottom: window.innerHeight - selfwindow.height,
      }}
      onDrag={handleDrag}
      onStop={handleStop}
    >
      <div>
        <AnimatePresence exitBeforeEnter>
            <motion.div
              variants={variants}
              initial="exit"
              animate={isActive ? 'enter' : 'exit'}
              exit="exit"
              transition={{ duration: 0.2 }}
              ref={self}
              className={`corners p-1 pt-0 dark:bg-light-bg-100 bg-dark-bg-800`}
              style={{
                position: 'absolute',
                width: props.width,
                height: 'max-content',
                opacity: isActive ? 1 : 0.5,
                pointerEvents: isActive ? 'all' : 'none',
              }}>
              <WindowBar key={`${props.title}-window`} title={props.title} minimizeFunction={handleMinimize} isMinimized={isMinimized} ToolTip={props.ToolTip} />
              <div className={`${isMinimized ? "opacity-0 h-0 pointer-events-none" : 'opacity-1'} `}>
                <>
                  <div className='h-[1px] dark:bg-primary-900 bg-primary-100 opacity-10 mx-1' />
                  {props.children}
                </>
              </div>
            </motion.div>
        </AnimatePresence>
      </div>
    </Draggable>
  )
}


const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export default WindowUnclose


