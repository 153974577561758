import { motion } from 'framer-motion'
import React from 'react'
import { MdComputer } from 'react-icons/md'
import { AiOutlineMobile } from 'react-icons/ai'

const PhoneModal = () => {

    return (
        <div
            className='absolute left-1/2 top-1/4 -translate-x-1/2 -translate-y-1/2 w-[300px] corners p-2 pb-4 px-4 dark:bg-light-bg-100 bg-dark-bg-800 shadow-lg pointer-events-auto'
        >
            <div className='flex justify-center mt-2 mb-2'>
                <a
                    href='https://leogadil.com/projects/spaceverse'
                    className='text-light-bg-100 dark:text-dark-bg-900 text-2xl text-bold font-koulen tracking-widest'>
                    SPACEVERSE
                </a>
            </div>
            <div className='flex flex-col'>
                <p className='mt-1 mb-6 opacity-75 px-2 text-light-bg-100 dark:text-dark-bg-900 text-sm text-center'>
                    virtual universes for studying and working. It
                    is still in development but you can already try it out.<br /> It currently supports the following platforms:
                </p>
                <div className='flex flex-col gap-2 mt-2'>
                    <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className='cursor-pointer flex justify-center items-center gap-2 dark:bg-white-accent bg-black-accent corners p-2'>
                        <MdComputer />
                        <span className='text-light-bg-100 dark:text-dark-bg-900'>PC Browser</span>
                    </motion.div>
                    <motion.div
                    className='opacity-50 flex justify-center items-center gap-2 dark:bg-white-accent bg-black-accent corners p-2'>
                        <AiOutlineMobile />
                        <span className='line-through text-light-bg-100 dark:text-dark-bg-900'>Mobile Browser</span>
                    </motion.div>
                </div>
                <p className='mt-3 px-2 opacity-75 text-light-bg-100 dark:text-dark-bg-900 text-sm text-center'>
                    Not Available on your current platform yet.
                </p>
            </div>
        </div>
    )
}

export default PhoneModal