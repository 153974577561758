import { WidgetActionTypes } from "../actionTypes/WidgetActionTypes";
import { Dispatch } from "redux";
import { WidgetAction } from "../actions";
import React from "react";

export const toggleWidget = (
    id: string
) => {
    return (dispatch: Dispatch<WidgetAction>) => {
        dispatch({
            type: WidgetActionTypes.TOGGLE_ACTIVE
            , id: id
        });
    }
}

export const setWidgetPosition = (
    id: string
    , position: {
        x: number;
        y: number;
    }
) => {
    return (dispatch: Dispatch<WidgetAction>) => {
        dispatch({
            type: WidgetActionTypes.SET_POSITION
            , id: id
            , position: position
        });
    }
}

export const toggleWidgetMinimized = (
    id: string
) => {
    return (dispatch: Dispatch<WidgetAction>) => {
        dispatch({
            type: WidgetActionTypes.TOGGLE_MINIMIZED
            , id: id
        });
    }
}


export const subscribeWidget = (
    id: string, 
    icon: React.ReactNode, 
    name: string,
    position: { x: number, y: number },
    active? : boolean,
    isMinimized?: boolean
) => {
    return (dispatch: Dispatch<WidgetAction>) => {
        dispatch({
            type: WidgetActionTypes.SUBSCRIBE_WIDGET,
            id: id, 
            active: active || false,
            icon: icon,
            name: name,
            position: {
                x: position.x ,
                y: position.y 
            },
            isMinimized: isMinimized || false
        })
    };
}

export const unsubscribeWidget = (
    id: string
) => {
    return (dispatch: Dispatch<WidgetAction>) => {
        dispatch({
            type: WidgetActionTypes.UNSUBSCRIBE_WIDGET
            , id: id
        })
    }
}
