import { WidgetActionTypes } from "../actionTypes";

export const middleware = (store: any) => (next: any) => (action: any) => {

    switch (action.type) {
        case WidgetActionTypes.SUBSCRIBE_WIDGET:
            const { id, active, position, isMinimized } = action;
            const widget = {
                id: id,
                active: active,
                position: {
                    x: position.x, 
                    y: position.y
                },
                isMinimized: isMinimized
            }
            let savedWidget = localStorage.getItem('savedWidget');
            if (savedWidget === null) {
                localStorage.setItem('savedWidget', '{}');
                savedWidget = '{}';
            }
            let savedwidgets = JSON.parse(savedWidget);
            savedwidgets[id] = widget;
            localStorage.setItem('savedWidget', JSON.stringify(savedwidgets));
            break;
        case WidgetActionTypes.SET_POSITION:
            const { id: id2, position: position2 } = action;
            let savedWidget2 = localStorage.getItem('savedWidget');
            if (savedWidget2 === null) {
                localStorage.setItem('savedWidget', '{}');
                savedWidget2 = '{}';
            }
            let savedwidgets2 = JSON.parse(savedWidget2);
            savedwidgets2[id2].position = {
                x: position2.x,
                y: position2.y
            }
            localStorage.setItem('savedWidget', JSON.stringify(savedwidgets2));
            break;
        case WidgetActionTypes.TOGGLE_ACTIVE:
            const { id: id3 } = action;
            let savedWidget3 = localStorage.getItem('savedWidget');
            if (savedWidget3 === null) {
                localStorage.setItem('savedWidget', '{}');
                savedWidget3 = '{}';
            }
            let savedwidgets3 = JSON.parse(savedWidget3);
            savedwidgets3[id3].active = !savedwidgets3[id3].active;
            localStorage.setItem('savedWidget', JSON.stringify(savedwidgets3));
            break;
        case WidgetActionTypes.TOGGLE_MINIMIZED:
            const { id: id4 } = action;
            let savedWidget4 = localStorage.getItem('savedWidget');
            if (savedWidget4 === null) {
                localStorage.setItem('savedWidget', '{}');
                savedWidget4 = '{}';
            }
            let savedwidgets4 = JSON.parse(savedWidget4);
            savedwidgets4[id4].isMinimized = !savedwidgets4[id4].isMinimized;
            localStorage.setItem('savedWidget', JSON.stringify(savedwidgets4));
            break;
    }

    return next(action);
}