export enum EToolTipType {
    TEXT = 'text',
    MODAL = 'modal',
}

export enum EToolTipDirection {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
}