import React from 'react'
import { motion } from 'framer-motion'
import { ITask } from '../../../lib/interfaces'
import ToolTip from '../tooltip'
import { EToolTipDirection } from '../../../lib/enums'

const Task: React.FC<ITask> = (props) => {

  const [isToolTipVisible, setIsToolTipVisible] = React.useState(false)

  return (
    <>
      <motion.div
        onMouseEnter={() => setIsToolTipVisible(true)}
        onMouseMove={() => setIsToolTipVisible(true)}
        onMouseLeave={() => setIsToolTipVisible(false)}
        className={`${props.className} dark:bg-light-bg-100 bg-dark-bg-800 shadow-xl corners cursor-pointer p-1 pointer-events-auto`}
        whileTap={props.disableAnimation ? {} : props.whileTap || { scale: 0.9 }}
        whileHover={props.disableAnimation ? {} : props.whileHover || { scale: 1.1 }}
        onMouseUp={props.onMouseUp}
      >
        {props.children}
      </motion.div>
      {
        isToolTipVisible && props.ToolTip && props.ToolTip.text && 
        <ToolTip
          text={props.ToolTip?.text}
          width={props.ToolTip.width}
          type={props.ToolTip.type}
          direction={props.ToolTip?.direction || EToolTipDirection.TOP} />
      }
    </>
  )
}

export default Task