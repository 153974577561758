

export const playlists = [
    {
        id: '7',
        link: 'https://open.spotify.com/playlist/2LcJR9ibDpLhhJfxX49aSz?si=9e1c9f8739d74e0a',
        value: '2LcJR9ibDpLhhJfxX49aSz',
        name: 'Feeling Something',
        from: 'Leo'
    },
    {
        id: '1',
        link: `https://open.spotify.com/playlist/37i9dQZF1DXbQm6Guuzt5a`,
        value: '37i9dQZF1DXbQm6Guuzt5a',
        name: 'sadboi hours',
        from: 'erika'
    },
    {
        id: '2',
        link: `https://open.spotify.com/playlist/37i9dQZF1DWW2hj3ZtMbuO`,
        name: 'sad girl starter pack',
        value: '37i9dQZF1DWW2hj3ZtMbuO',
        from: 'erika'
    },
    {
        id: '3',
        link: `https://open.spotify.com/playlist/1eioN9k0XVxR408VcOrS00?si=7f81252f59a04327`,
        value: '1eioN9k0XVxR408VcOrS00',
        name: 'vibin /w the vibe',
        from: 'matt'
    },
    {
        id: '4',
        link: `https://open.spotify.com/playlist/37i9dQZF1DWUZjBKjkexPW?si=34e67d69748d432d`,
        value: '37i9dQZF1DWUZjBKjkexPW',
        name: 'wdy',
        from: 'matt'
    },
    {
        id: '5',
        link: `https://open.spotify.com/playlist/1dl8LEvYodKp7LdM7jmIF4?si=183292bec9644d4a`,
        value: '1dl8LEvYodKp7LdM7jmIF4',
        name: 'seggs playlist',
        from: 'matt',
        isPrivate: true
    },
    {
        id: '6',
        link: `https://open.spotify.com/playlist/1eMp1FK6sIK0N01vjD1TO3?si=8db2aafcaa7e480b`,
        value: '1eMp1FK6sIK0N01vjD1TO3',
        name: 'a trip from yesterday',
        from: 'marc',
        isPrivate: true,
        purpose: 'self pleasure'
    }
]