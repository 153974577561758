import { createPortal } from 'react-dom'
import React from 'react'
import { NoticeStatus } from '../lib/localstorageManager'
import {
	Time,
	Timer,
	Logo,
	Toggle,
	Menu,
	UniverseSetting,
	SpotifyPlayer,
	Notice,
	AppMenu,
	Account,
	DevelopmentBanner,
} from './ui-components'
import { EToolTipType } from '../lib/enums'

// const UI: React.FC = () => {

// 	const [isFirstRun] = React.useState(NoticeStatus())

// 	return (
// 		<div className='absolute w-screen h-screen p-2 z-20'>
// 			<Widgets />
// 		</div>
// 	)

// 	// return (
// 	// 	<div className='absolute w-screen h-screen p-2 z-20'>
// 	// 		<div className='flex flex-col-reverse h-full'>
// 	// 			<div className='absolute left-0 top-0 w-screen h-screen'>
// 	// 				{
// 	// 					isFirstRun && (<Notice />)
// 	// 				}
// 	// 				<Time />
// 	// 				<SpotifyPlayer />
// 	// 				<Timer />
// 	// 				<SpaceSetting />
// 	// 			</div>
// 	// 			<div className='flex flex-row gap-2 absolute '>
// 	// 				<Logo />
// 	// 				<Menu />
// 	// 				<Toggle />
// 	// 			</div>
// 	// 		</div>

// 	// 	</div>
// 	// )
// }

const Widgets: React.FC = () => {
	return createPortal(
		<>
			<Time />
			<SpotifyPlayer />
			<Timer />
			<UniverseSetting />
		</>
		,
		document.getElementById('widget') as Element
	)
}

const Notices: React.FC = () => {

	return createPortal(
		<>
			<Notice />
		</>
		,
		document.getElementById('notice') as Element
	)
}

const UI: React.FC = () => {
	return createPortal(
		<div className='p-2 absolute bottom-0 w-full'>
			<div className='flex justify-between w-full gap-2'>
				<div className='flex flex-row gap-2 flex-1'>
					<Logo />
					<Menu />
					<Toggle />
				</div>
				<div className='flex-1 flex justify-center'>
					<DevelopmentBanner />
				</div>
				<div className='flex flex-row-reverse gap-2 flex-1'>
					{/* <AppMenu />
					<Account /> */}
				</div>
			</div>
		</div>
		,
		document.getElementById('ui') as Element
	)
}

export {
	Widgets,
	UI,
	Notices,
}