import React, { useEffect, useRef, useState } from 'react'
import { Window } from '../templates'
import { MdSpaceDashboard } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators } from '../../../lib/state'
import { bindActionCreators } from '@reduxjs/toolkit'
import { ISpaceControls, IUniverseState, ISpaceVolume } from '../../../lib/interfaces'
import { BsBookmarkHeart, BsFillBookmarkHeartFill } from 'react-icons/bs'
import { MdVolumeUp, MdVolumeOff } from 'react-icons/md'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { spaces } from '../../../data/spaces'
import ErrorHandler from '../errorHandler'

// MAKE EVERYTHING HERE HAVE A DEDICATED USESTATE
// MAKE EVERYTHING HERE HAVE A DEDICATED USESTATE
// MAKE EVERYTHING HERE HAVE A DEDICATED USESTATE
// MAKE EVERYTHING HERE HAVE A DEDICATED USESTATE

const UniverseSetting: React.FC = () => {

  const dispatch = useDispatch()
  const {
    setSpace,
    changeVolume,
    muteSpace,
    unmuteSpace,
    changeFavorite,
  } = bindActionCreators(actionCreators, dispatch)
  const general: IUniverseState = useSelector((state: any) => state.universe)
  const [volume, setVolume] = useState(general.volume)

  const toggleMute = () => {
    if (general.volume > 0) {
      setVolume(general.volume)
      muteSpace(volume)
    } else {
      unmuteSpace(volume)
    }
  }

  const [width] = useState(300)
  
  return (
    <Window width={width} icon={MdSpaceDashboard} otherProps={general.universe} title={'Space'} defaultPosition={{ x: 1, y: 45 }} defaultActive>
      <div className='p-2'>
        <div className='flex flex-col gap-2'>
          <SpaceControl setSpace={setSpace} currentSpace={general.universe} />
          <SpaceData />
          <div className='flex flex-row items-center justify-between pt-2 mt-4'>
            <div className='flex flex-col text-light-bg-100 dark:text-dark-bg-900'>
              <a rel="noopener noreferrer" target="_blank" href={general.universe.link} className='font-bold text-md hover:underline'>{general.universe.name}</a>
            </div>
            <div className='w-[50px] flex justify-center'>
              <button className='text-light-bg-100 dark:text-dark-bg-900' onClick={() => changeFavorite(!general.universe.isFavorite)}>
                {
                  general.universe.isFavorite ? <BsFillBookmarkHeartFill size={20} className='text-danger-500' /> : <BsBookmarkHeart size={20} />
                }
              </button>
            </div>
          </div>
          <VolumeControl volume={general.volume} onMute={toggleMute} onChange={(e) => changeVolume(parseFloat(e.target.value))} />
        </div>
      </div>
    </Window>
  )
}

const SpaceData: React.FC = () => {

  const general: IUniverseState = useSelector((state: any) => state.universe)
  const scrollRef = useHorizontalScroll()

  return (
    <div ref={scrollRef} className='w-full scrollbar overflow-x-auto rounded-md shadow-sm pb-2'>
      <div className='flex flex-row gap-2'>
        {
          general.universe.keywords.map((keyword, index) => {
            return (
              <div key={index} className='flex flex-row items-center bg-dark-bg-400 dark:bg-light-bg-500 px-2 corners'>
                <span className='text-light-bg-100 dark:text-dark-bg-900 text-sm whitespace-nowrap'>{keyword}</span>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const SpaceControl: React.FC<ISpaceControls> = (props) => {

  const [idx, setIdx] = useState(spaces.findIndex((space) => space === props.currentSpace))

  const previus = () => {
    if (idx > 0) {
      setIdx(idx - 1)
    } else {
      setIdx(spaces.length - 1)
    }
  }

  const next = () => {
    if (idx < spaces.length - 1) {
      setIdx(idx + 1)
    } else {
      setIdx(0)
    }
  }

  useEffect(() => {
    if (spaces[idx] === props.currentSpace) return // temporarily fix the bug where the space is changing whenever the widget closes and reopens

    props.setSpace(spaces[idx])
  }, [idx])

  return (
    <div className='flex justify-between gap-2 h-[40px] mb-2'>
      <button
        onClick={previus}
        className='w-[40px] hover:bg-light-bg-900 hover:dark:bg-dark-bg-100 ring-1 dark:ring-dark-bg-200 ring-light-bg-900 h-full corners flex justify-center items-center text-light-bg-100 dark:text-dark-bg-900'>
        <FaAngleLeft size={20} />
      </button>
      <div
        className='corners ring-1 dark:ring-dark-bg-200 ring-light-bg-900 flex-1 flex justify-center items-center'
      >
        <span className='text-light-bg-100 dark:text-dark-bg-900'>{props.currentSpace.category.length > 15 ? props.currentSpace.category.slice(0, 15) + "..." : props.currentSpace.category}</span>
      </div>
      <button
        onClick={next}
        className='w-[40px] hover:bg-light-bg-900 hover:dark:bg-dark-bg-100 ring-1 dark:ring-dark-bg-200 ring-light-bg-900 h-full corners flex justify-center items-center text-light-bg-100 dark:text-dark-bg-900'>
        <FaAngleRight size={20} />
      </button>
    </div>
  )
}

const VolumeControl: React.FC<ISpaceVolume> = (props) => {

  return (
    <div>
      <div className='flex flex-row items-center gap-2'>
        <button className='text-light-bg-100 dark:text-dark-bg-900' onClick={() => { props.onMute() }} >
          {
            props.volume > 0 ? <MdVolumeUp size={20} /> : <MdVolumeOff size={20} />
          }
        </button>

        <input
          type="range"
          name="volume"
          id="volume"
          className='form-range appearance-none accent-danger-500 w-full h-[1px] rounded-md p-0 m-2 focus:outline-none bg-dark-bg-100 dark:bg-light-bg-600 focus:ring-0 focus:shadow-none '
          onChange={props.onChange}
          value={props.volume}
        />
      </div>

    </div>
  )
}

export function useHorizontalScroll() {
  const elRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e: any) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: "smooth"
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

export default UniverseSetting