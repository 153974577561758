import { ISpotifyPlaylistState } from "../../interfaces";
import { playlists } from "../../../data/spotifyplaylists";
import { SpotifyPlaylistAction } from "../actions";
import { SpotifyPlaylistActionTypes } from "../actionTypes";


const initialState: ISpotifyPlaylistState = {
    playlist: playlists[0],
}

const spotifyPlaylistReducer = (state = initialState, action : SpotifyPlaylistAction) => {
    switch (action.type) {
        case SpotifyPlaylistActionTypes.CHANGE_PLAYLIST:
            return {
                ...state,
                playlist: action.playlist 
            }
        default:
            return state;
    }
}

export default spotifyPlaylistReducer;

export type SpotifyPlaylistReducerState = ReturnType<typeof spotifyPlaylistReducer>;
