import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { EToolTipDirection } from '../../lib/enums'
import { IToolTip } from '../../lib/interfaces'



const ToolTip: React.FC<IToolTip> = (props) => {

    const [mousePosition, setMousePosition] = useState({ x: -window.innerWidth, y: -window.innerHeight })
    const [direction, setDirection] = useState<EToolTipDirection>(props.direction)
    const self = useRef<HTMLDivElement>(null)

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove)
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
    }, [])

    const handleMouseMove = (e: MouseEvent) => {
        if(!self.current) return;

        const offsets = {
            'top': { x: -props.width / 2, y: -self.current?.offsetHeight + -15 },
            'bottom': { x: -props.width / 2, y: 25 },
            'left': { x: -props.width - 25, y: -self.current?.offsetHeight / 2 },
            'right': { x: 25, y: -self.current?.offsetHeight / 2 },
        }

        let offset = offsets[direction]

        if(e.pageY + offset.y < 0) {
            if(direction !== EToolTipDirection.BOTTOM) {
                setDirection(EToolTipDirection.BOTTOM)
                offset = offsets[EToolTipDirection.BOTTOM]
            }
        }
        if(e.pageY + offset.y + self.current?.offsetHeight > window.innerHeight) {
            if(direction !== EToolTipDirection.TOP) {
                setDirection(EToolTipDirection.TOP)
                offset = offsets[EToolTipDirection.TOP]
            }
        }
        if(e.pageX + offset.x < 0) {
            if(direction !== EToolTipDirection.RIGHT) {
                setDirection(EToolTipDirection.RIGHT)
                offset = offsets[EToolTipDirection.RIGHT]
            }
        } 
        if(e.pageX + offset.x + self.current?.offsetWidth > window.innerWidth) {
            if(direction !== EToolTipDirection.LEFT) {
                setDirection(EToolTipDirection.LEFT)
                offset = offsets[EToolTipDirection.LEFT]
            }
        }
        
        let newPosition = { x: e.pageX + offset.x, y: e.pageY + offset.y }

        setMousePosition(newPosition)
    }

    const variants = {
        visible: {
            opacity: 1,
            transition: {
                duration: 0.2,
                ease: 'easeInOut',
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.2,
                ease: 'easeInOut',
            },
        },
    }

    return createPortal(
        <div>
            <AnimatePresence exitBeforeEnter>
                <motion.div
                    ref={self}
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    className={`absolute dark:bg-light-bg-100 bg-dark-bg-800 shadow-lg corners flex justify-center items-center p-2 px-2 `}
                    style={{
                        opacity: 0,
                        width: props.width,
                        top: mousePosition.y,
                        left: mousePosition.x,
                    }}
                >
                    <span className='text-light-bg-100 dark:text-dark-bg-900 text-sm white text-center'>
                        {props.text}
                    </span>
                </motion.div>
            </AnimatePresence>
        </div>
        , document.getElementById('tooltip') as Element)
}

export default ToolTip