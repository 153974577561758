import { Dispatch } from "react";
import { IAccount } from "../../interfaces";
import { AccountAction } from "../actions";
import { AccountActionTypes } from "../actionTypes";

export const setAccount = (
    account: IAccount
) => {
    return (dispatch: Dispatch<AccountAction>) => {
        dispatch({
            type: AccountActionTypes.SET_ACCOUNT
            , account: account
        });
    }
}

export const removeAccount = (
) => {
    return (dispatch: Dispatch<AccountAction>) => {
        dispatch({
            type: AccountActionTypes.REMOVE_ACCOUNT
        });
    }
}

export const changeAccountName = (
    value: string
) => {
    return (dispatch: Dispatch<AccountAction>) => {
        dispatch({
            type: AccountActionTypes.CHANGE_ACCOUNT_NAME
            , value: value
        });
    }
}

export const changeAccountShortName = (
    value: string
) => {
    return (dispatch: Dispatch<AccountAction>) => {
        dispatch({
            type: AccountActionTypes.CHANGE_ACCOUNT_SHORTNAME
            , value: value
        });
    }
}

export const changeAccountEmail = (
    value: string
) => {
    return (dispatch: Dispatch<AccountAction>) => {
        dispatch({
            type: AccountActionTypes.CHANGE_ACCOUNT_EMAIL
            , value: value
        });
    }
}

export const changeAccountBio = (
    value: string
) => {
    return (dispatch: Dispatch<AccountAction>) => {
        dispatch({
            type: AccountActionTypes.CHANGE_ACCOUNT_BIO
            , value: value
        });
    }
}

