import React from 'react'
import { Task } from './templates'

const Logo : React.FC = () => {
    return (
        <Task className='flex flex-row justify-center items-center p-1 px-3 pt-[5px]' disableAnimation>
            <a 
                href='https://leogadil.com/projects/spaceverse'
                className='text-light-bg-100 dark:text-dark-bg-900 text-lg text-bold font-koulen tracking-widest'>
                    SPACEVERSE
            </a>
        </Task>
    )
}

export default Logo