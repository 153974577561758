import React from 'react'
import { WindowUnclose } from '../templates'
import { SiSpotify } from 'react-icons/si'
import { playlists } from '../../../data/spotifyplaylists'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { actionCreators } from '../../../lib/state'
import { ISpotifyPlaylistState } from '../../../lib/interfaces'
import { EToolTipDirection, EToolTipType } from '../../../lib/enums'

const SpotifyPlayer: React.FC = () => {

  const [width] = React.useState(375)

  const ToolTip = {
    width: 200,
    type: EToolTipType.TEXT,
    direction: EToolTipDirection.TOP,
    text: <span>You need to login to <span className='text-[#1DB954]'>Spotify</span> in your browser to use this feature properly.</span>,
  }

  return (
    <WindowUnclose title={"Spotify"} width={width} icon={SiSpotify} defaultPosition={{ x: 98, y: 20 }} ToolTip={ToolTip}>
      <div className='flex flex-col gap-2 py-2 pb-0'>
        <PlayerPlaylist />
        <Player />
      </div>
    </WindowUnclose>
  )
}

const PlayerPlaylist: React.FC = () => {

  const dispatch = useDispatch()
  const {
    changePlaylist
  } = bindActionCreators(actionCreators, dispatch)

  const handleChangePlaylist = (e: any) => {
    const playlistData = playlists.find(p => p.id === e.target.value)
    if (!playlistData) return
    changePlaylist(playlistData)
  }
  const state: ISpotifyPlaylistState = useSelector((state: ISpotifyPlaylistState) => state.spotifyPlaylist)

  return (
    <div className='flex flex-row gap-2 items-center px-1'>
      <div>
        <span className='text-light-bg-100 dark:text-dark-bg-900'>Playlist</span>
      </div>
      <select
        className='appearance-none flex-1 corners ring-1 p-1 px-3 dark:bg-light-bg-100 bg-dark-bg-800 text-sm text-light-bg-100 dark:text-dark-bg-900'
        name=""
        id=""
        onChange={handleChangePlaylist}
      >
        {
          playlists.map((playlist, index) => {
            if (playlist.isPrivate) return null

            return (
              <option
                key={index}
                className='text-sm text-light-bg-100 dark:text-dark-bg-900'
                value={playlist.id}
                defaultValue={state.id}
              >
                {playlist.name} - {playlist.from}
              </option>
            )
          })
        }
      </select>
    </div>
  )
}

const Player: React.FC = () => {

  const state: ISpotifyPlaylistState = useSelector((state: ISpotifyPlaylistState) => state.spotifyPlaylist)

  return (
    <div style={{
      height: '380px',
      width: '100%',
    }}>
      <iframe
        title='youtube-player'
        className='corners'
        src={`https://open.spotify.com/embed/playlist/${state.playlist.value}?utm_source=generator&size=detail&theme=white`}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      />
    </div>

  )

  // <iframe 
  //   src="https://open.spotify.com/embed/playlist/37i9dQZF1DWUZjBKjkexPW?utm_source=generator&amp&size=detail&amp;theme=light" 
  //   style="border:0px #ffffff none;" 
  //   name="myiFrame" 
  //   scrolling="yes" 
  //   frameborder="1" 
  //   marginheight="0px" 
  //   marginwidth="0px" 
  //   height="400px" 
  //   width="600px" 
  //   allowfullscreen="">
}

export default SpotifyPlayer