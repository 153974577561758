import { Dispatch } from "redux";
import { TPremadeTime, TTimerType } from "../../types";
import { TimerAction } from "../actions";
import { TimerActionTypes } from "../actionTypes";

export const startTimer = (

) => {
    return (dispatch: Dispatch<TimerAction>) => {
        dispatch({
            type: TimerActionTypes.START_TIMER
        });
    }
}

export const stopTimer = (

) => {
    return (dispatch: Dispatch<TimerAction>) => {
        dispatch({
            type: TimerActionTypes.STOP_TIMER
        });
    }
}

export const resetTimer = (

) => {
    return (dispatch: Dispatch<TimerAction>) => {
        dispatch({
            type: TimerActionTypes.RESET_TIMER
        });
    }
}

export const setTimerType = (
    num: number
) => {
    return (dispatch: Dispatch<TimerAction>) => {
        dispatch({
            type: TimerActionTypes.SET_TIMER_TYPE
            , num: num
        });
    }
}

export const addPremadeTime = (
    payload : TPremadeTime
) => {
    return (dispatch: Dispatch<TimerAction>) => {
        dispatch({
            type: TimerActionTypes.ADD_PREMADE_TIME
            , payload: payload
        });
    }
}

export const removePremadeTime = (
    id: string
) => {
    return (dispatch: Dispatch<TimerAction>) => {
        dispatch({
            type: TimerActionTypes.REMOVE_PREMADE_TIME
            , id: id
        });
    }
}

export const addTimerType = (
    payload : TTimerType
) => {
    return (dispatch: Dispatch<TimerAction>) => {
        dispatch({
            type: TimerActionTypes.ADD_TIMERTYPE
            , payload: payload
        });
    }
}

export const removeTimerType = (
    id: string
) => {
    return (dispatch: Dispatch<TimerAction>) => {
        dispatch({
            type: TimerActionTypes.REMOVE_TIMERTYPE
            , id: id
        });
    }
}

export const addTime = (
    value: number
) => {
    return (dispatch: Dispatch<TimerAction>) => {
        dispatch({
            type: TimerActionTypes.ADD_TIME
            , value: value
        });
    }
}

export const timerTick = (
    
) => {
    return (dispatch: Dispatch<TimerAction>) => {
        dispatch({
            type: TimerActionTypes.TIMER_TICK
        });
    }
}
