import { bindActionCreators } from '@reduxjs/toolkit'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IUniverseState, IVideoBackgroundLoadingScreen } from '../lib/interfaces'
import { actionCreators } from '../lib/state'
import Youtube, { YouTubeProps } from 'react-youtube'
import { TUniverse } from '../lib/types'

const Universe: React.FC = () => {

    const general: IUniverseState = useSelector((state: any) => state.universe)

    return createPortal(
        <div className='overflow-hidden'>
            <SpaceLoading isLoading={general.loading} />
            <div className='absolute w-screen h-screen pb-0 lg:pb-[56.25%] video-background z-0'>
                <SpaceVideo />
            </div>
        </div>,
        document.getElementById('root') as Element
    )
}

const SpaceVideo: React.FC = () => {

    const dispatch = useDispatch()
    const {
        changeLoading,
    } = bindActionCreators(actionCreators, dispatch)
    const general: IUniverseState = useSelector((state: any) => state.universe)

    const [video, setVideo] = useState<TUniverse>(general.universe)
    const [target, setTarget] = useState<any>(null)

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            mute: 0,
            autoplay: 1,
            controls: 0,
            loop: 1,
            playlist: video.value,
            rel: 0,
            modestBrand: 1,
            fs: 0,
        }
    }

    const onPlay: YouTubeProps['onPlay'] = (e) => {
        changeLoading(false)
    }

    //onstatechange
    const onStateChange: YouTubeProps['onStateChange'] = (e) => {

        if (e.data === 1) {
            changeLoading(false)
        }

        if(e.data === -1) {
            e.target.playVideo()
        }
    }

    //onError
    const onError: YouTubeProps['onError'] = (e) => {
        //do something here in the future
    }

    //onReady
    const onReady: YouTubeProps['onReady'] = (e) => {
        e.target.playVideo()
        e.target.setVolume(general.volume)
        setTarget(e.target)
    }

    useEffect(() => {
        if (!target) return
        target.setVolume(general.volume)

    }, [general.volume])

    useEffect(() => {
        setVideo(general.universe)
    }, [general.universe])

    return (
        <Youtube
            key={video.value}
            videoId={video.value}
            opts={opts}
            className='video-background'
            iframeClassName='pointer-events-none absolute top-0 left-0 bottom-0 right-0 z-0'
            id='Youtube-Video-Background'
            onPlay={onPlay}
            onReady={onReady}
            onStateChange={onStateChange}
            onError={onError}
        />
    )
}

const SpaceLoading: React.FC<IVideoBackgroundLoadingScreen> = (props) => {

    const [isLoading, setIsLoading] = useState(props.isLoading)

    const threeDotAnimation = [
        ".", "..", "..."
    ]

    const variants = {
        initial: {
            opacity: 0,
            transition: {
                duration: 0.5,
                ease: "easeInOut"
            }
        },
        animate: {
            opacity: 1,
            transition: {
                duration: 0.5,
                ease: "easeInOut"
            }
        }
    }

    const [frame, setFrame] = useState(0)

    const animate = () => {
        setFrame(frame => frame >= threeDotAnimation.length - 1 ? 0 : frame + 1)
    }

    useEffect(() => {
        const inter = setInterval(() => {
            animate()
        }, 500)

        return () => clearInterval(inter)
    }, [])

    useEffect(() => {
        setIsLoading(props.isLoading)
    }, [props.isLoading])

    // useEffect(() => {
    //   const timou = setTimeout(() => {
    //     setIsLoading(false)
    //   }, 3000)

    //   return () => clearTimeout(timou)
    // }, [props.isLoading])

    return createPortal(
        <AnimatePresence exitBeforeEnter>
            {
                isLoading &&
                <motion.div
                    variants={variants}
                    initial="animate"
                    animate={isLoading ? "animate" : "initial"}
                    exit="initial"
                    className='absolute bg-light-bg-100 dark:bg-dark-bg-800 w-full h-full overflow-hidden z-1'>
                    <motion.span
                    variants={variants}
                    initial="initial"
                    animate={isLoading ? "animate" : "initial"}
                    exit="initial"
                    className='dark:text-light-bg-100 text-dark-bg-900 flex flex-col justify-center items-center w-full h-full'>
                        <span className='text-4xl font-bold'>Your Universe</span>
                        <span>is loading{threeDotAnimation[frame]}</span>
                        <span className='text-sm  mt-5 opacity-50'>If loading goes on for 10 seconds</span>
                        <span className='text-sm opacity-50'>Please Refresh the page</span>

                    </motion.span>
                </motion.div>
            }
        </AnimatePresence>
    , document.getElementById('loading') as Element)
}

export default Universe


