
// NOTICE

export const InitialAcknowledgeNotice = () => {
    const initialRun = localStorage.getItem('AcknowledgeNotice');
    if (initialRun === null) {
        localStorage.setItem('AcknowledgeNotice', 'true');
        return true;
    }
    return false;
}

export const NoticeStatus = () => {
    const initialRun = localStorage.getItem('AcknowledgeNotice');
    if (initialRun === 'true') {
        return true;
    }
    return false;
}

export const NoticeAcknowledged = () => {
    localStorage.setItem('AcknowledgeNotice', 'false');
}

// END NOTICE

// WIDGET

export const InitialSavedWidget = () => {
    const savedWidget = localStorage.getItem('savedWidget');
    if (savedWidget === null) {
        localStorage.setItem('savedWidget', '{}');
        return true;
    }
    return false;
}

export const GetSavedWidget = (key: string) => {
    const savedWidget = localStorage.getItem('savedWidget');
    if (savedWidget === null) return null;
    let savedwidgets = JSON.parse(savedWidget);
    if (savedwidgets[key] === null) return {};
    return savedwidgets[key];
}

export const SetSavedWidget = (key: string, value: any) => {
    const savedWidget = localStorage.getItem('savedWidget');
    if (savedWidget === null) return null;
    let savedwidgets = JSON.parse(savedWidget);
    savedwidgets[key] = value;
    localStorage.setItem('savedWidget', JSON.stringify(savedwidgets));
}

// END WIDGET

// ACCOUNT

export const SetIsHereB4True = () => {
    localStorage.setItem('isHereB4', '0');
}

export const SetIsHereB4False = () => {
    localStorage.removeItem('isHereB4');
}

export const GetIsHereB4 = () => {
    const isHereB4 = localStorage.getItem('isHereB4');
    if (isHereB4 === null) return false;
    return true;
}

// END ACCOUNT