import React, { useState, useEffect } from 'react'
import { TimerSwitcher, TimerPremadeList } from '../components'
import { ITimerMainButton, ITimerState, IWidget } from '../../../lib/interfaces'
import { Window } from '../templates'
import { RiTimerFill } from 'react-icons/ri'
import { TTimerType } from '../../../lib/types'
import { VscDebugRestart } from 'react-icons/vsc'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { actionCreators } from '../../../lib/state'

const Timer: React.FC<IWidget> = (props) => {

  const dispatch = useDispatch()
  const {
    startTimer,
    stopTimer,
    timerTick,
    resetTimer,
    setTimerType
  } = bindActionCreators(actionCreators, dispatch)
  const general: ITimerState = useSelector((state: ITimerState) => state.timers)
  const type: TTimerType = useSelector((state: ITimerState) => state.timers.type[general.selected])

  const [width, setWidth] = useState(300)

  const [minutesText, setMinutesText] = useState('00')
  const [secondsText, setSecondsText] = useState('00')

  const toggleTimer = () => {
    if (!general.isRunning && general.seconds > 0) timerTick()
    if (general.isRunning) stopTimer()
    else startTimer()
  }

  const tick = () => {
    if (general.seconds > 1) timerTick()
    else onReachZero()
  }

  const onReachZero = () => {
    if (!general.isRunning) return;

    stopTimer()
    if (general.selected === 0) setTimerType(1)
    else if (general.selected === 1) setTimerType(0)
    else if (general.selected === 2) setTimerType(0)

    const tout = setTimeout(() => {
      timerTick()
      startTimer()
      clearTimeout(tout)
    }, 1000)
  }

  const updateText = () => {
    let minutes = Math.floor(general.seconds / 60)
    let seconds_ = general.seconds - minutes * 60

    setMinutesText(minutes.toString().padStart(2, '0'))
    setSecondsText(seconds_.toString().padStart(2, '0'))
  }

  const reset = () => {
    stopTimer()
    resetTimer()
  }

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (!general.isRunning) return;
      tick()
    }
      , 1000)
    return () => window.clearInterval(interval)
  }, [, general.isRunning, general.seconds])

  useEffect(() => {
    updateText()
  }, [, general.seconds, general.selected, general.isRunning])

  useEffect(() => {
    if (type === undefined) return;
    reset()
  }, [, type])

  return (
    <Window width={width} title="Timer" icon={RiTimerFill} defaultPosition={{ x: 50, y: 2 }}>
      <div className='p-2'>
        <TimerSwitcher />
        <div className=''>
          <div className='grid grid-cols-4 py-2'>
            <div className='col-span-2'>
              <span className='text-4xl flex items-center text-light-bg-100 dark:text-dark-bg-900'>
                <span className=''>{minutesText}</span>
                <span className='text-2xl'>:</span>
                <span className=''>{secondsText}</span>
                {/* {general.seconds} */}
              </span>
            </div>
            <div className='col-span-2 flex items-center justify-end my-1 gap-2'>
              <MainButton
                onClick={() => toggleTimer()}
                className={`${!general.isRunning ? 'dark:bg-green-primary-100 bg-green-primary-dark' : 'dark:bg-red-primary-100 bg-red-primary-dark'} w-[75px]`}
                value={general.isRunning ? 'Stop' : 'Start'}
              />
              <MainButton
                onClick={() => reset()}
                className='w-[40px] hover:bg-light-bg-900 hover:dark:bg-dark-bg-100'
                value={<VscDebugRestart />}
              />
            </div>
          </div>
          <TimerPremadeList />
        </div>
      </div>
    </Window>
  )
}

const MainButton = (props: ITimerMainButton) => {
  return (
    <button
      onClick={props.onClick}
      className={`ring-1 dark:ring-dark-bg-200 ring-light-bg-900 h-full corners flex justify-center items-center ${props.className} `}>
      <span className={` ${!props.isPrimaryButton ? 'text-light-bg-100 dark:text-dark-bg-900' : 'text-light-bg-100 '} `}>{props.value}</span>
    </button>
  )
}


export default Timer


// return (
//   <Window width={width} height={height} title="Timer" icon={MdOutlineTimer}>
//     <div className='px-2 pt-2 pb-1'>
//       <TimerSwitcher />
//       <div className='flex flex-col gap-2'>
//         <div className='flex-1 grid grid-cols-6 justify-center'>
//           <div className='col-span-2 flex flex-row'>
//             <span className='font-ubuntu text-4xl font-bold text-light-bg-100 dark:text-dark-bg-900 flex flex-row items-center'>
//               <span className=''>{minutesText}</span>
//               <span className='text-2xl px-1'>:</span>
//               <span className=''>{secondsText}</span>
//               {/* {general.seconds} */}
//             </span>
//           </div>
//           <div className='col-span-4 flex flex-row items-start gap-2 justify-end'>
//             <button
//               onClick={() => toggleTimer()}
//               className={`text-2xl rounded-md w-[75px] h-[37px] border-[1px] dark:border-dark-bg-900 border-opacity-10 dark:border-opacity-20 border-dark-bg-100 flex flex-col justify-center items-center ${general.isRunning ? 'bg-red-primary-dark dark:bg-red-primary-100' : 'dark:bg-green-primary-100 bg-green-primary-dark'} transition-colors`}>
//               <span className='text-sm text-light-bg-100 dark:text-dark-bg-900'>{general.isRunning ? "Stop" : "Start"}</span>
//             </button>
//             <button
//               onClick={() => reset()}
//               className='text-2xl rounded-md w-[40px] h-[37px] border-[1px] dark:border-dark-bg-900 border-opacity-10 dark:border-opacity-50 dark:hover:border-opacity-50 hover:bg-dark-bg-500 hover:dark:bg-light-bg-400 border-dark-bg-100 flex flex-col justify-center items-center'>
//               <span className='text-sm text-light-bg-100 dark:text-dark-bg-900'><VscDebugRestart /></span>
//             </button>
//           </div>
//         </div>
//         <TimerPremadeList />
//       </div>
//     </div>
//   </Window>
// )