import { Dispatch } from 'redux';
import { TCategory, TUniverse } from '../../types';
import { SpaceAction } from '../actions';
import { UniverseActionTypes } from '../actionTypes';

export const setSpace = (
    payload: TUniverse
) => {
    return (dispatch: Dispatch<SpaceAction>) => {
        dispatch({
            type: UniverseActionTypes.SET_UNIVERSE
            , payload: payload
        });
    }
}

export const changeVolume = (
    value: number
) => {
    return (dispatch: Dispatch<SpaceAction>) => {
        dispatch({
            type: UniverseActionTypes.CHANGE_VOLUME
            , value: value
        });
    }
}

export const changeLoading = (
    value: boolean
) => {
    return (dispatch: Dispatch<SpaceAction>) => {
        dispatch({
            type: UniverseActionTypes.CHANGE_LOADING
            , value: value
        });
    }
}

export const muteSpace = (
    value: number
) => {
    return (dispatch: Dispatch<SpaceAction>) => {
        dispatch({
            type: UniverseActionTypes.MUTE_UNIVERSE
            , value: value
        });
    }
}

export const unmuteSpace = (
    value: number
) => {
    return (dispatch: Dispatch<SpaceAction>) => {
        dispatch({
            type: UniverseActionTypes.UNMUTE_UNIVERSE
            , value: value
        });
    }
}

export const changeFavorite = (
    value: boolean
) => {
    return (dispatch: Dispatch<SpaceAction>) => {
        dispatch({
            type: UniverseActionTypes.CHANGE_FAVORITE
            , value: value
        });
    }
}

export const setCategories = (
    payload: Array<TCategory>
) => {
    return (dispatch: Dispatch<SpaceAction>) => {
        dispatch({
            type: UniverseActionTypes.SET_CATEGORIES
            , payload: payload
        });
    }
}