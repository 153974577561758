import React from 'react';
import { Universe, Widgets, UI, Notices } from './components';
// import { isBrowser } from 'react-device-detect';

const App: React.FC = () => {
  return (
    <>
      <Universe />
      <UI />
      <Widgets />
      <Notices />
    </>
  );
}

export default App;
