import React from 'react';
import { BsFillMoonFill } from 'react-icons/bs';
import { ImSun } from "react-icons/im";
import { EToolTipDirection, EToolTipType } from '../../lib/enums';
import { ThemeContext } from '../../lib/themecontext';
import { Task } from './templates'

const Toggle: React.FC = () => {
    
    const { theme, setTheme } = React.useContext(ThemeContext);

    const ToolTip = {
        width: 120,
        type: EToolTipType.TEXT,
        direction: EToolTipDirection.TOP,
        text: 'toggle theme',
    }

    return (
        <Task
            onMouseUp={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            className="flex flex-row justify-center items-center px-3 p-2"
            whileTap={{ scale: 0.8 }}
            whileHover={{ scale: 1.1 }}
            ToolTip={ToolTip}
        >
            {theme === 'dark' ? (
                <ImSun className="text-light-bg-100 dark:text-dark-bg-900 text-xl cursor-pointer" />
            ) : (
                <BsFillMoonFill className="text-light-bg-100 dark:text-dark-bg-900 text-xl cursor-pointer" />
            )}
        </Task>
    );
};

export default Toggle;