import { WidgetAction } from "../actions";
import { IWidgetState } from "../../interfaces";
import { WidgetActionTypes } from "../actionTypes/WidgetActionTypes";

const initialState : IWidgetState = {
    widget: [],
}

const WidgetReducer = (state = initialState, action: WidgetAction) => {
    switch (action.type) {
        case WidgetActionTypes.SUBSCRIBE_WIDGET:
            if (state.widget.find(widget => widget.id === action.id)) return state;

            return {
                ...state,
                widget: [...state.widget, {
                    name: action.name,
                    id: action.id,
                    icon: action.icon,
                    active: action.active,
                    position: {
                        x: action.position?.x,
                        y: action.position?.y
                    },
                    isMinimized: action.isMinimized
                }]
            }
        case WidgetActionTypes.TOGGLE_ACTIVE:
            if (!state.widget.find(widget => widget.id === action.id)) return state;

            return {
                ...state,
                widget: state.widget.map(widget => {
                    if (widget.id === action.id) {
                        return {
                            ...widget,
                            active: !widget.active,
                        }
                    }
                    return widget;
                })
            }
        case WidgetActionTypes.UNSUBSCRIBE_WIDGET:
            if (!state.widget.find(widget => widget.id === action.id)) return state;

            return {
                ...state,
                widget: state.widget.filter(widget => widget.id !== action.id)
            }
        case WidgetActionTypes.SET_POSITION:
            if (!state.widget.find(widget => widget.id === action.id)) return state;

            return {
                ...state,
                widget: state.widget.map(widget => {
                    if (widget.id === action.id) {
                        return {
                            ...widget,
                            position: {
                                x: action.position?.x,
                                y: action.position?.y
                            }
                        }
                    }
                    return widget;
                })
            }
        case WidgetActionTypes.TOGGLE_MINIMIZED:
            if (!state.widget.find(widget => widget.id === action.id)) return state;

            return {
                ...state,
                widget: state.widget.map(widget => {
                    if (widget.id === action.id) {
                        return {
                            ...widget,
                            isMinimized: !widget.isMinimized,
                        }
                    }
                    return widget;
                })
            }
        default:
            return state;
    }
}

export default WidgetReducer;

export type WidgetReducerState = ReturnType<typeof WidgetReducer>;