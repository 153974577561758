export enum TimerActionTypes {
    START_TIMER = 'START_TIMER',
    STOP_TIMER = 'STOP_TIMER',
    SET_TIMER_TYPE = 'SET_TIMER_TYPE',
    ADD_PREMADE_TIME = 'ADD_PREMADE_TIME',
    REMOVE_PREMADE_TIME = 'REMOVE_PREMADE_TIME',
    ADD_TIMERTYPE = 'ADD_TIMERTYPE',
    REMOVE_TIMERTYPE = 'REMOVE_TIMERTYPE',
    ADD_TIME = 'ADD_TIME',
    TIMER_TICK = 'TIMER_TICK',
    RESET_TIMER = 'RESET_TIMER',
}