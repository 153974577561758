import React, { useState, useEffect } from 'react'
import { IWidget } from '../../../lib/interfaces'
import { Window } from '../templates'
import { ImClock2 } from 'react-icons/im'

const Time: React.FC<IWidget> = (props) => {

  const getCurTime = () => {
    const date = new Date()
    const hours = date.getHours() < 12 ? `0${date.getHours()}` : date.getHours() - 12
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM'
    const curTime = `${hours}:${minutes} ${ampm}`
    return { hours, minutes, ampm }
  }

  const [time, setTime] = useState(getCurTime())
  const [width, setWidth] = useState(175)

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getCurTime())
    }
      , 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Window width={width} title="Clock" icon={ImClock2} defaultPosition={{ x: 1, y: 2 }}>
      <div className='pt-2 pb-2 px-4 flex flex-row justify-center items-center'>
        <span className='text-4xl  text-light-bg-100 dark:text-dark-bg-900 flex flex-row items-center'>
          <span>{time.hours}</span>
          <span className='text-2xl mx-1'>:</span>
          <span>{time.minutes}</span>
          <span className='text-sm ml-2 font-normal opacity-50'>{time.ampm}</span>
        </span>
      </div>
    </Window>
  )
}

export default Time