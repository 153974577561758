import React from 'react'
import { IWindowBar } from '../../../lib/interfaces'
import { TiMinus } from 'react-icons/ti'
import { CgMaximizeAlt } from 'react-icons/cg'
import { HiInformationCircle } from 'react-icons/hi'
import ToolTip from '../tooltip'
import { EToolTipDirection } from '../../../lib/enums'

const WindowBar: React.FC<IWindowBar> = (props) => {

	const [isToolTipVisible, setIsToolTipVisible] = React.useState(false)

	return (
		<>
			<div
				key={`${props.title}-windowbar`}
				className='w-full flex px-1 flex-row justify-between items-center mb-[1px] cursor-grab active:cursor-grabbing drag-area'>
				<span className='text-light-bg-100 dark:text-dark-bg-900 text-lg p-1 py-[2px] flex items-center gap-1'>
					{props.title}
					{
						props.ToolTip &&
						<div className='pb-2'>
							<HiInformationCircle size={15}
								className='cursor-pointer'
								onMouseEnter={() => setIsToolTipVisible(true)}
								onMouseLeave={() => setIsToolTipVisible(false)}
							/>
						</div>
					}
				</span>
				<div className='flex flex-row mt-[4px]'>
					<button onClick={props.minimizeFunction}>
						{!props.isMinimized ?
							<TiMinus className='text-light-bg-100 dark:text-dark-bg-900' />
							: <CgMaximizeAlt className='text-light-bg-100 dark:text-dark-bg-900' />}
					</button>
				</div>
				{
					isToolTipVisible && props.ToolTip &&
					<ToolTip
						text={props.ToolTip?.text}
						width={props.ToolTip.width}
						type={props.ToolTip.type}
						direction={props.ToolTip?.direction || EToolTipDirection.BOTTOM} />
				}
			</div>
		</>
	)
}

export default WindowBar