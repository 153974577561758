import { Dispatch } from "redux";
import { TSpotifyPlaylist } from "../../types";
import { SpotifyPlaylistAction } from "../actions";
import { SpotifyPlaylistActionTypes } from "../actionTypes";

export const changePlaylist = (
    payload : TSpotifyPlaylist
) => {
    return (dispatch: Dispatch<SpotifyPlaylistAction>) => {
        dispatch({
            type: SpotifyPlaylistActionTypes.CHANGE_PLAYLIST
            , playlist: payload
        });
    }
}