import { combineReducers } from 'redux';
import WidgetReducer from './widgetReducer';
import TimerReducer from './timerReducer';
import universeReducerState from './universeReduer';
import spotifyPlaylistReducer from './spotifyPlaylistReducer';
import AccountReducer from './accountReducer';


const reducers = combineReducers({
    widgets: WidgetReducer,
    timers: TimerReducer,
    universe: universeReducerState,
    spotifyPlaylist: spotifyPlaylistReducer,
    account: AccountReducer,
});

export default reducers;