import { bindActionCreators } from '@reduxjs/toolkit'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ITimerState } from '../../../lib/interfaces'
import { actionCreators } from '../../../lib/state'
import { TTimerType } from '../../../lib/types'

const TimerSwitcher: React.FC = () => {

  const dispatch = useDispatch()
  const {
    setTimerType,
  } = bindActionCreators(actionCreators, dispatch)
  const state = useSelector((state: ITimerState) => state.timers.type)
  const general = useSelector((state: ITimerState) => state.timers)

  return (
    <div className='grid grid-flow-col gap-2'>
      {
        state && state.map((item: TTimerType, idx: number) => {
          return (
            <button
              key={item.id}
              onClick={() => setTimerType(idx)}
              className={`${general.selected === idx ? 'ring-1 dark:ring-dark-bg-200 ring-light-bg-900 bg-light-bg-900 dark:bg-light-bg-500' : 'hover:bg-light-bg-900 hover:dark:bg-dark-bg-100'} px-1 corners flex justify-center items-center py-[1px]`}>
              <span className='text-xs text-light-bg-100 dark:text-dark-bg-900'>{item.label}</span>
            </button>
          )
        })
      }
    </div>
  )
}

export default TimerSwitcher