import { bindActionCreators } from '@reduxjs/toolkit'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ITimerState } from '../../../lib/interfaces'
import { actionCreators } from '../../../lib/state'
import { TPremadeTime, TTimerType } from '../../../lib/types'

const TimerPremadeList: React.FC = () => {

  const dispatch = useDispatch()
  const {
    addTime
  } = bindActionCreators(actionCreators, dispatch)
  const state : Array<TPremadeTime> = useSelector((state: ITimerState) => state.timers.premade)
  const general = useSelector((state: ITimerState) => state.timers)
  const type : TTimerType = useSelector((state: ITimerState) => state.timers.type[general.selected])

  return (
    <div className='grid grid-flow-col gap-2'>
      {
        state && state.map((item : TPremadeTime, idx: number) => {
          // find the current premade time to the selected timer type
          if(!type.premade.find((premade: string) => premade === item.id)) return;

          return (
            <button
              key={item.id}
              className='h-5 ring-1 dark:ring-dark-bg-200 ring-light-bg-900 hover:bg-light-bg-900 hover:dark:bg-dark-bg-100 corners flex justify-center items-center'
              onClick={() => addTime(item.value)}>
              <span className='text-xs text-light-bg-100 dark:text-dark-bg-900'>{item.label}</span>
            </button>
          )
        })
      }
    </div>
  )
}

export default TimerPremadeList