import React from 'react'
import { PhoneModal } from './components/ui-components'

const PhoneApp = () => {
  return (
    <div>
      <PhoneModal />
    </div>
  )
}

export default PhoneApp