import { bindActionCreators } from '@reduxjs/toolkit'
import axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IPreload } from './lib/interfaces'
import { actionCreators } from './lib/state'
import { TTimerType } from './lib/types'

const PreLoad: React.FC<IPreload> = (props) => {

  const dispatch = useDispatch()
  const {
    addTimerType,
    removeTimerType,
    addPremadeTime,
    removePremadeTime,
    setAccount,
    setCategories
  } = bindActionCreators(actionCreators, dispatch)

  useEffect(() => {
    for (let i = 0; i < defaultSwitches.length; i++) {
      const element = defaultSwitches[i];
      addTimerType(element)
    }

    for (let i = 0; i < premadeTime.length; i++) {
      const element = premadeTime[i];
      addPremadeTime(element)
    }

    return () => {
      for (let i = 0; i < defaultSwitches.length; i++) {
        const element = defaultSwitches[i];
        removeTimerType(element.id)
      }

      for (let i = 0; i < premadeTime.length; i++) {
        const element = premadeTime[i];
        removePremadeTime(element.id)
      }
    }
  }, [])

  useEffect(() => {
    axios.get('/v1/api/users/me')
      .then(res => {
        setAccount(res.data.user)
      })
      .catch(err => {
        console.log(err)
      })

    axios.get('/v1/api/categories')
      .then(res => {
        setCategories(res.data.categories)
        console.log(res.data.categories)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  return (
    <>
      {props.children}
    </>
  )
}

export default PreLoad

const defaultSwitches: Array<TTimerType> = [
  {
    id: "pomodoro-type",
    name: "pomodoro",
    label: "Pomodoro",
    defaultTime: 1200,
    time: {
      seconds: 0,
    },
    premade: [
      "5mins-premade", "10mins-premade", "20mins-premade"
    ]
  },
  {
    id: "shortbreak-type",
    name: "short-break",
    label: "Short Break",
    defaultTime: 5,
    time: {
      seconds: 0,
    },
    premade: [
      "5mins-premade", "10mins-premade", "20mins-premade"
    ]
  },
  {
    id: "longbreak-type",
    name: "long-break",
    label: "Long Break",
    defaultTime: 600,
    time: {
      seconds: 0,
    },
    premade: [
      "5mins-premade", "10mins-premade", "20mins-premade"
    ]
  }
]

const premadeTime = [
  { 
    id: '5mins-premade', 
    name: '5 minutes', 
    label: '5mins', 
    value: 300 
  },
  { 
    id: '10mins-premade', 
    name: '10 minutes', 
    label: '10mins', 
    value: 600 
  },
  { 
    id: '20mins-premade', 
    name: '20 minutes', 
    label: '20mins', 
    value: 1200 
  }
]