import React from 'react'
import { EToolTipDirection, EToolTipType } from '../../lib/enums'
import { Task } from './templates'

const DevelopmentBanner = () => {

  const ToolTip = {
    width: 240,
    type: EToolTipType.TEXT,
    direction: EToolTipDirection.TOP,
    text: 'Spaceverse is in development',
  }

  return (
    <Task className='flex items-center h-10 p-2 px-4 bg-danger-500 dark:bg-danger-500' ToolTip={ToolTip}>
      <span className='uppercase text-lg text-light-bg-100 whitespace-nowrap tracking-wider'>In Development</span>
    </Task>
  )
}

export default DevelopmentBanner