import { ITimerState } from "../../interfaces";
import { TimerAction } from "../actions";
import { TimerActionTypes } from "../actionTypes";

const initialState: ITimerState = {
    isRunning: false,
    selected: 0,
    seconds: 0,
    type: [],
    premade: [],
}

const TimerReducer = (state = initialState, action: TimerAction) => {
    switch(action.type) {
        case TimerActionTypes.TIMER_TICK:
            if(state.seconds === 0) return 
            return {
                ...state,
                seconds: state.seconds - 1,
            }
        case TimerActionTypes.START_TIMER:
            return {
                ...state,
                isRunning: true,
            }
        case TimerActionTypes.STOP_TIMER:
            return {
                ...state,
                isRunning: false,
            }
        case TimerActionTypes.RESET_TIMER:
            let type_default = 0
            if(state.type.length != 0) {
                type_default = state.type[state.selected].defaultTime;
            } else {
                type_default = 1200;
            }

            return {
                ...state,
                seconds: type_default,
            } 
        case TimerActionTypes.SET_TIMER_TYPE:
            return {
                ...state,
                selected: action.num,
            }
        case TimerActionTypes.ADD_PREMADE_TIME:

            return {
                ...state,
                premade: [...state.premade, action.payload],
            }
        case TimerActionTypes.REMOVE_PREMADE_TIME:
            return {
                ...state,
                premade: state.premade.filter(premade => premade.id !== action.id),
            }
        case TimerActionTypes.ADD_TIMERTYPE:

            return {
                ...state,
                type: [...state.type, action.payload],
            }
        case TimerActionTypes.REMOVE_TIMERTYPE:
            return {
                ...state,
                type: state.type.filter(type => type.id !== action.id),
            }
        case TimerActionTypes.ADD_TIME:
            return {
                ...state,
                seconds: state.seconds + action.value,
            }
        default:
            return state;
    }
}

export default TimerReducer;

export type TimerReducerState = ReturnType<typeof TimerReducer>;