import { IUniverseState } from "../../interfaces";
import { SpaceAction } from "../actions";
import { UniverseActionTypes } from "../actionTypes";
import { spaces } from "../../../data/spaces";

const initialState : IUniverseState = {
    universe: spaces[0],
    availableCategories: [],
    volume: 0,
    loading: true,
}

const universeReducer = (state = initialState, action: SpaceAction) => {
    switch (action.type) {
        case UniverseActionTypes.SET_UNIVERSE:
            return {
                ...state,
                universe: action.payload,
                loading: true
            }
        case UniverseActionTypes.CHANGE_VOLUME:
            return {
                ...state,
                volume: action.value,
            }
        case UniverseActionTypes.CHANGE_LOADING:
            return {
                ...state,
                loading: action.value,
            }
        case UniverseActionTypes.MUTE_UNIVERSE:
            return {
                ...state,
                lastVolume: action.value,
                volume: 0,
            }
        case UniverseActionTypes.UNMUTE_UNIVERSE:
            return {
                ...state,
                volume: action.value,
                lastVolume: 0,
            }
        case UniverseActionTypes.CHANGE_FAVORITE:
            return {
                ...state,
                space: {
                    ...state.space,
                    isFavorite: action.value,
                }
            }
        case UniverseActionTypes.SET_CATEGORIES:
            return {
                ...state,
                availableCategories: action.payload,
            }
        default:
            return state;

    }
}

export default universeReducer;

export type universeReducerState = ReturnType<typeof universeReducer>;
