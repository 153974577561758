import { TUniverse } from "../lib/types";

export const spaces: TUniverse[] = [
    {
        id: "1",
        name: "Night In An Exclusive Luxury Miami Apartment",
        link: "https://www.youtube.com/watch?v=QUqhgZjrrsE",
        value: "QUqhgZjrrsE",
        keywords: [
            "miami", "luxury", "apartment", "night", "exclusive", "indoor"
        ],
        category: "indoor",
        isFavorite: false
    },
    {
        id: "2",
        name: "Onda Beach Relaxing Waves",
        link: "https://www.youtube.com/watch?v=Xn8tufsbSz0",
        value: "Xn8tufsbSz0",
        keywords: [
            "beach", "relaxing", "waves", "ocean", "vacation", "ocean"
        ],
        category: "beach",
        isFavorite: false
    },
    {
        id: "3",
        name: "Campfire by Lake at Sunset",
        link: "https://www.youtube.com/watch?v=-wKiNZ-u-HA",
        value: "-wKiNZ-u-HA",
        keywords: [
            "campfire", "lake", "sunset", "vacation", "lake"
        ],
        category: "camping",
        isFavorite: false
    },
    {
        id: "4",
        name: "Snowy Weather in your Apartment",
        link: "https://www.youtube.com/watch?v=iIuuMNbSjDE",
        value: "iIuuMNbSjDE",
        keywords: [
            "snow", "winter", "apartment", "candles", "cold"
        ],
        category: "indoor",
        isFavorite: false
    },
    {
        id: "5",
        name: "Spring Coffee Shop Ambience",
        link: "https://www.youtube.com/watch?v=uby4pigzp6Q",
        value: "uby4pigzp6Q",
        keywords: [
            "coffee", "shop", "spring", "vacation", "waterfall"
        ],
        category: "cafe",
        isFavorite: false
    },
    {
        id: "6",
        name: "You're in Toronto, Window",
        link: "https://www.youtube.com/watch?v=bLAzpxidPN0",
        value: "bLAzpxidPN0",
        keywords: [
            "toronto", "window", "vacation", "city"
        ],
        category: "window",
        isFavorite: false
    },
    {
        id: "7",
        name: "Alkaline - Move Mountains",
        link: "https://www.youtube.com/watch?v=V_qSR3yEiCs",
        value: "V_qSR3yEiCs",
        keywords: [
            "mountains", "vacation", "lake", "lake", "lake"
        ],
        category: "songs about love",
        isFavorite: false        
    },
    {
        id: "8",
        name: "Glee - Don't Stop Believin'",
        link: "https://www.youtube.com/watch?v=JseWhrUz9TY",
        value: "JseWhrUz9TY",
        keywords: [
            "glee", "believin", "glee cast", "music"
        ],
        category: "Glee Cast",
        isFavorite: true
    },
    {
        id: "9",
        name: "Glee - Don't Stop Believin",
        link: "https://www.youtube.com/watch?v=kNw38HBDkOo",
        value: "kNw38HBDkOo",
        keywords: [
            "Glee Club Gone", "Ending", "Performance", "Memorable"
        ],
        category: "Glee Cast 100th EP",
        isFavorite: true
    },
    {
        id: "10",
        name: "GLEE - We Are Young",
        link: "https://www.youtube.com/watch?v=a-VhdeuRtwE",
        value: "a-VhdeuRtwE",
        keywords: [
            "Glee Club", "We are young", "Give me a second hand"
        ],
        category: "Glee Cast",
        isFavorite: true
    },
    {
        id: "11",
        name: "Glee - Stereo Heart",
        link: "https://www.youtube.com/watch?v=02lW6TpjVJk",
        value: "02lW6TpjVJk",
        keywords: [
            "Glee Club", "Stereo Heart", "Valentines Day"
        ],
        category: "Valentines Day",
        isFavorite: true
    },
    {
        id: "12",
        name: "Glee - Raise Your Glass",
        link: "https://www.youtube.com/watch?v=mG08XO_MagE",
        value: "mG08XO_MagE",
        keywords: [
            "Glee Club", "Raise Your Glass", "Regionals"
        ],
        category: "Glee - Raise Your Glass",
        isFavorite: true
    },
    {
        id: "13",
        name: "Glee - Loser Like Me",
        link: "https://www.youtube.com/watch?v=bjKcwjvbZLM",
        value: "bjKcwjvbZLM",
        keywords: [
            "Glee Club", "Loser Like Me", "Original"
        ],
        category: "Glee - Loser Like Me",
        isFavorite: true
    },
    {
        id: "14",
        name: "Glee - Cough Syrup",
        link: "https://www.youtube.com/watch?v=56zJlSBIqRo",
        value: "56zJlSBIqRo",
        keywords: [
            "Glee Club", "Cough Syrup", "Sad"
        ],
        category: "Glee - Cough Syrup",
        isFavorite: true
    }
]
